import {Toast} from "vant";

export function copy(text) {
    const range = document.createRange();
    const selection = window.getSelection();
    if (!navigator.clipboard) {
        range.selectNode(document.getElementById("device"));
        if (selection.rangeCount > 0) selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        Toast.success({message: "复制成功", forbidClick: true, duration: 1000});
        return;
    }
    navigator.clipboard.writeText(text).then(() => {
        Toast.success({message: "复制成功", forbidClick: true, duration: 1000});
    }).catch(() => {
        range.selectNode(document.getElementById("device"));
        if (selection.rangeCount > 0) selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        Toast.success({message: "复制成功", forbidClick: true, duration: 1000});
    });
}
